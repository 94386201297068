.w_max_content {
  --header_height: 85px;
  --sidemenu_width: 300px;
  width: calc(100% - var(--sidemenu_width));
  height: calc(100vh - var(--header_height));
  overflow-y: auto;
}

.bg_image {
  object-fit: contain;
}

.bg_image_background {
  border-radius: 50%;
  height: 273px;
  overflow: hidden;
}

.w_273 {
  width: 273px;
}

.bg_main_image {
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
}

.bg_main_image_overlay {
  position: absolute;
  top: 0;
}

.z_index_1 {
  z-index: 1;
}

.card_img_parent {
  width: 90px;
  height: 105px;
}

.card_img {
  object-fit: cover;
  border-radius: 8px;
  object-position: top;
}

.playicon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.time {
  position: absolute;
  bottom: 0;
}

.border_bottom {
  padding-top: 4px;
}
