/**
 * Grid setup
 *
 * The grid will calculate dimensions based on these two variables:
 * $columns-size will inform the grid loops how many columns there should be.
 *
 * The grid will name columns, rows, offsets based on these three variables:
 * $class-row string used for the row class
 * $class-col string used for the column class
 * $class-offset string used for the offset class
 */
$columns-size: 12 !default;

$class-row: "row" !default;
$class-col: "col" !default;
$class-offset: "offset" !default;

/* Break point namespace object */
$media-breakpoints: (
  (xs),
  /* eg col-6 offset-3 */ (sm, 768px),
  /* eg col-sm-6 offset-sm-3  */ (md, 992px),
  /* eg col-md-6 offset-md-3 */ (lg, 1200px) /* eg col-lg-6 offset-lg-3 */
) !default;

/* Calculate column size percentage */
@function get-col-percent($column-num) {
  @return calc($column-num / $columns-size) * 100%;
}

/**
 * Row wrapper class, flex box parent.
 */
.#{ $class-row } {
  display: flex;
  flex-wrap: wrap;
}

/**
 * Generate a set of grid column classes using a namespace
 *
 * .#{ $class-col }-[namespace] for intelligent column division
 * .#{ $class-col }-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * .#{ $class-offset }-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 * .#{ $class-offset }-[namespace]-reset for resetting a col's offset for that and larger namespaces
 */

@mixin grid-sizes($namespace) {
  // Run the loop for as many columns as speficied
  @for $i from 1 through $columns-size {
    @if ($namespace !=xs) {
      .#{ $class-col }-#{$namespace}-#{$i} {
        flex: 0 0 auto;
        width: get-col-percent($i);
      }
    } @else {
      .#{ $class-col }-#{$i} {
        flex: 0 0 auto;
        width: get-col-percent($i);
      }
    }
  }

  @for $i from 1 through $columns-size {
    // Don't include the .offset class for the last column
    @if ($i !=$columns-size) {
      @if ($namespace !=xs) {
        .#{ $class-offset }-#{$namespace}-#{$i} {
          margin-left: get-col-percent($i);
        }
      } @else {
        .#{ $class-offset }-#{$i} {
          margin-left: get-col-percent($i);
        }
      }
    }
  }
}

/**
 * Build the grid in two steps, to help minimize file size
 */
@each $val in $media-breakpoints {
  $namespace: nth($val, 1);
}

@each $val in $media-breakpoints {
  @if length($val) ==1 {
    $namespace: nth($val, 1);
    @include grid-sizes($namespace);
  } @else {
    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      @include grid-sizes($namespace);
    }
  }
}

.col {
  flex: 0 0 auto;
}
