@mixin generate($prefix, $property) {
  // List of sizes to generate for each
  $sizes: (0, 8, 16, 24, 40, 48);
  // Spacing to multiply the sizes by
  $spacing: 1px;

  // Loop through all of the sizes(we use @for rather than @each, as we want access to the index)
  @for $i from 1 through length($sizes) {
    // Get the size for the current index
    $size: nth($sizes, $i);

    // Create the rule
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size;
    }
  }
}

$rules: (
  (m, margin),
  (ml, margin-left),
  (mt, margin-top),
  (mb, margin-bottom),
  (mr, margin-right),
  (mx, margin-inline),
  (my, margin-block),
  (p, padding),
  (pl, padding-left),
  (pt, padding-top),
  (pb, padding-bottom),
  (pr, padding-right),
  (px, padding-inline),
  (py, padding-block),
  (br, border-radius)
);

@each $item in $rules {
  @include generate(nth($item, 1), nth($item, 2));
}

@mixin generateAuto($prefix, $property) {
  .#{$prefix}-auto {
    #{$property}: auto;
  }
}

$rules: (
  (m, margin),
  (ml, margin-left),
  (mt, margin-top),
  (mb, margin-bottom),
  (mr, margin-right),
  (mx, margin-inline),
  (my, margin-block)
);

@each $item in $rules {
  @include generateAuto(nth($item, 1), nth($item, 2));
}
