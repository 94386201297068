.card {
  border-radius: 8px;
  width: 350px;
}

.image {
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  height: 196px;
}

.badge {
  width: 33px;
  height: 33px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.time {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.new_badge {
  position: absolute;
  left: 10px;
  top: 10px;
}

.cards_parent {
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.overflow_y_scroll {
  overflow-y: scroll;
  height: calc(100vh - 85px);
}
