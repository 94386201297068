.card {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card_logo {
  position: absolute;
  left: 24px;
  height: calc(100% - 40px);
}

.main {
  min-height: 90px;
}
