$white: #ffffff;
$black: #222222;
$light-gray: #f1f1f1;
$gray: #f6f6f6;
$light-red: #f6604b;
$red: #f6604b;
$dark-red: #fe4257;
$light-blue: #5ab6db;
$blue: #52aae6;
$dark-blue: #4b9ff1;
$link-primary: #f95047;
$black-29: #00000029;
$link-secondary: #4da2ef;
$pink: #f8d6d3;
$sea-blue: #d4e5f5;

// color, background-color, border-color classes
$colours: (
  'white' $white,
  'black' $black,
  'lgray' $light-gray,
  'gray' $gray,
  'link-primary' $link-primary,
  'link-secondary' $link-secondary,
  'pink' $pink,
  'sea-blue' $sea-blue,
  'light-red' $light-red,
  'red' $red,
  'dark-red' $dark-red
);

@each $i in $colours {
  .#{nth($i, 1)} {
    color: nth($i, 2);
  }

  .bg-#{nth($i, 1)} {
    background: nth($i, 2);
  }

  .border-#{nth($i, 1)} {
    border-color: nth($i, 2);
  }

  .loader-#{nth($i, 1)} {
    border-color: nth($i, 2) !important;
    border-top-color: transparent !important;
  }
}

// gradient classes
$gradients: (
  'btn-primary' $light-red$dark-red,
  'btn-secondary' $light-blue$dark-blue
);

@each $i in $gradients {
  .#{nth($i, 1)} {
    background: transparent
      linear-gradient(180deg, nth($i, 2) 0%, nth($i, 3) 100%)
      0%
      0%
      no-repeat
      padding-box;
    color: $white;
  }
}
