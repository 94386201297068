@font-face {
  font-family: "Roboto Regular";
  src: url("./Roboto/Regular/Roboto-Regular.ttf") format("truetype");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Bold";
  src: url("./Roboto/Bold/Roboto-Bold.ttf") format("truetype");

  font-weight: bold;
  font-style: normal;
}


$sizes: (14, 17, 20, 24, 32, 48);

@for $i from 1 through length($sizes) {
  // Get the size for the current index
  $size: nth($sizes, $i);

  // Create the rule
  .font-#{$size} {
    font-size: $size * 1px;
  }
}
