.header {
  position: sticky;
  top: 0;
  border-bottom-right-radius: 40px;
  box-shadow: 0px 0px 10px #00000029;
  align-items: center;
  z-index: 2;
}

.back_button {
  height: 37px;
  width: 37px;
}

.primary {
  background: transparent linear-gradient(96deg, #f6604b 0%, #fe4257 100%) 0% 0%
    no-repeat padding-box;
}

.secondary {
  background: transparent
    linear-gradient(96deg, #59b5db 0%, #58b3dd 9%, #4b9ff0 100%) 0% 0% no-repeat
    padding-box;
}
