.button_position {
  position: fixed;
  right: 16px;
  top: 8px;
  z-index: 3;
}

.button {
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  font-weight: bold;
}

.button_border_right {
  border-right: 2px solid;
}
