.parent {
  margin-bottom: 100px;
  position: relative;
}

.header {
  position: sticky;
  top: 0;
  border-bottom-right-radius: 60px;
  z-index: 2;
}

.app_logo {
  height: 37px;
  width: 37px;
}

.h_50 {
  height: 50%;
}

.bg_body_card {
  background-image: url(../../images/bodybg.png);
  background-position: bottom -50px right -6px;
}

.bg_mind_card {
  background-image: url(../../images/mindbg.png);
  background-position: bottom -40px right -41px;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 3;
}

.modalIcon {
  height: 54px;
  width: 54px;
  border: 8px solid white;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -31px;
}

.border1 {
  border: 1px solid;
}

.br10 {
  border-radius: 10px;
}
