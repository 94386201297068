.card {
  box-shadow: 0px 3px 6px #2222221a;
  border-radius: 10px;
}

.active {
  font-weight: bold;
  margin-right: 0;
  border-radius: 10px 0 0 10px;
  margin-left: 8px;
}

.active_line {
  width: 9px;
  border-radius: 20px 0px 0px 20px;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
}

.w_300 {
  width: 300px;
  height: calc(100vh - 85px);
  overflow-y: scroll;
}
