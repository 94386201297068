.card {
  position: relative;
  overflow: hidden;
}

.bg_image {
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  position: relative;
}

.bg_image_background {
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  width: 185px;
  height: 180px;
  right: -33px;
  top: 91px;
  z-index: 0;
}
