// display related classes
$displays: (flex, block, grid, none);

@for $i from 1 through length($displays) {
  // Get the size for the current index
  $display: nth($displays, $i);

  // Create the rule
  .d-#{$display} {
    display: $display;
  }
}

// justify-content related classes
$justify-options: (center, space-between, space-around, flex-start, flex-end);

@for $i from 1 through length($justify-options) {
  $justify: nth($justify-options, $i);

  .j-c-#{$justify} {
    justify-content: $justify;
  }
}

// opacity related classes
$opacities: (0, 10, 30, 50, 80);

@for $i from 1 through length($opacities) {
  // Get the size for the current index
  $opacity: nth($opacities, $i);

  // Create the rule
  .o-#{$opacity} {
    opacity: $opacity * 1%;
  }
}

// width and height related classes
$size: (100);

@for $i from 1 through length($size) {
  // Get the size for the current index
  $size: nth($size, $i);

  // Create the rule
  .w-#{$size} {
    width: $size * 1%;
  }

  .h-#{$size} {
    height: $size * 1%;
  }
}

$loader-sizes: (4, 10);

@for $i from 1 through length($loader-sizes) {
  $size: nth($loader-sizes, $i);

  .loader-#{$size} {
    border: #{$size}px solid $light-gray;
    border-top: #{$size}px solid transparent;
    border-radius: 50%;
    width: #{$size * 6.75}px;
    height: #{$size * 6.75}px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
