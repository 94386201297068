.main {
  height: 100vh;
  overflow: hidden;
  z-index: 4;
}

.back_button {
  height: 37px;
  width: 37px;
}

.center {
  --SliderColor: transparent;
  position: absolute;
  inset: 0;
  margin: auto;
}

.show {
  position: absolute;
  inset: 0;
  background: #00000060;
}

.bottom_0 {
  position: absolute;
  bottom: 0;
}

.player {
  position: absolute;
  inset: 0px;
  margin: auto;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.progress_bar {
  -webkit-appearance: none;
  outline: none;
  border-radius: 8px;
  height: 8px;
  cursor: default;
}

.progress_bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--SliderColor);
  overflow: visible;
  cursor: pointer;
}

.z_index_4 {
  z-index: 4;
}
