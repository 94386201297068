$black: #222222;
$char-w: 1ch;
$gap: 0.5ch;
$n-char: 8;
$in-w: $n-char * ($char-w + $gap);

.input {
  display: inline-block;
  width: $in-w;
  padding: 0;
  background: repeating-linear-gradient(
      90deg,
      $black 0,
      $black $char-w,
      transparent 0,
      transparent $char-w + $gap
    )
    0 100% / #{$in-w} 2px no-repeat;
  font-family: monospace;
  letter-spacing: $gap;

  &:focus {
    outline: none;
  }
}

.app_logo {
  height: 133px;
  pointer-events: none;
}

.default_input {
  border: none;
  &:focus {
    outline: none;
  }
}

// placeholder color css
$placeholderClasses: (
  ":-webkit-input-placeholder",
  "-moz-placeholder",
  /* FF 4-18 */ ":-moz-placeholder ",
  /* FF 19+ */ "-ms-input-placeholder",
  /* IE 10+ */ ":-ms-input-placeholder",
  /* Microsoft Edge */ ":placeholder",
  /* modern browser */
);

@each $i in $placeholderClasses {
  .default_input:#{nth($i, 1)} {
    color: $black;
    opacity: 1;
  }
}

.input_parent {
  border-radius: 6px;
  position: relative;
}

.main {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error_message {
  background-color: #383838;
  border-radius: 0px 0px 6px 6px;
}

.image_border {
  --color: #cecece;
  border: 1px solid var(--color);
  border-radius: 50%;
  background-color: var(--color);
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
}

.error_border {
  border: 2px solid;
}
