@import 'utils/colorPalette';
@import 'utils/spacing';
@import 'utils/grid';
@import 'utils/mixins';
@import 'fonts/fonts';

body {
  margin: 0;
  font-family: 'Roboto Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  overscroll-behavior: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.heavy {
  font-family: 'Roboto Bold';
}

.font-w-n {
  font-weight: normal;
}

button {
  box-shadow: 0px 3px 6px $black-29;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}

.card {
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 3px 6px $black-29;
}

.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.disabled {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

div {
  position: relative;
}

.a-i-center {
  align-items: center;
}

.t-center {
  text-align: center;
}

.a-c-center {
  align-content: center;
}
